import React, { useState, useEffect } from "react";
import "firebase/firestore";
import { API, COLECCION_COBROLOCAL } from "../../../constantes";
import firebase from "../../../firebase";
import "firebase/firestore";

import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import dateFormat from "dateformat";

import MenuWeb from "./../menu";

import FechaEntrega from "./fechaEntrega";

import NumberFormat from "react-number-format";

import axios from "axios";

const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Cobros = () => {
  const classes = useStyles();

  const [cobrosLocales, setCobrosLocales] = useState([]);
  const [usuariosLocales, setUsuariosLocales] = useState("");

  const [cobros, setCobros] = useState([]);
  const [usuarios, setUsuarios] = useState("");

  const [usuario, setUsuario] = useState("");
  const [usuarioLocal, setUsuarioLocal] = useState("");

  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(
    new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000)
  );

  const [total, setTotal] = useState(0);
  const [totalLocal, setTotalLocal] = useState(0);

  useEffect(() => {
    const getNvByClients = async () => {
      let fechaDesde = desde;
      let fechaHasta = hasta;

      fechaDesde.setHours(0);
      fechaDesde.setMinutes(0);
      fechaHasta.setHours(23);
      fechaHasta.setMinutes(50);

      const { data } = await axios.post(
        API + "/api/v1/ventas/byclients",
        {
          init: fechaDesde,
          end: fechaHasta,
          clientes: ["W0", "W1", "W2", "W3", "W4"],
          mPagos: ["CA", "MP"],
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (data) {
        let users = data.reduce((prev, current) => {
          prev[current.cliente] = current.cliente;
          return prev;
        }, {});
        setCobros(data);
        setUsuarios(users);
      }

      db.collection(COLECCION_COBROLOCAL)
        .where("fecha", ">=", fechaDesde)
        .where("fecha", "<=", fechaHasta)
        .orderBy("fecha", "asc")
        .onSnapshot(function (querySnapshot) {
          let items = [];
          let emails = {};
          querySnapshot.forEach((d) => {
            items.push({ ...d.data(), id: d.id });
            // money = Number(money) + Number(d.data().monto);
            emails[d.data().email] = d.data().email;
          });
          setCobrosLocales(items);
          // setTotal(money);
          setUsuariosLocales(emails);
        });
    };
    getNvByClients();
  }, [desde, hasta]);

  useEffect(() => {
    let money = cobros
      .filter((d) => d.cliente.indexOf(usuario) > -1)
      .reduce(
        (a, b) =>
          a +
          b.articulos.reduce(
            (p, c) => p + c.cantidad * (c.precio * (1 - b.descuento / 100)),
            0
          ),
        0
      );
    let moneyLocal = cobrosLocales
      .filter((d) => d.email.indexOf(usuarioLocal) > -1)
      .reduce((a, b) => a + b.saldo, 0);
    setTotalLocal(moneyLocal);
    setTotal(money);
  }, [cobros, cobrosLocales, usuario, usuarioLocal]);

  return (
    <React.Fragment key={"bottom"}>
      <MenuWeb />
      <Paper style={{ margin: "4%" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "8px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flex: 1, gap: "10px" }}>
            <FechaEntrega
              setFecha={setDesde}
              min={new Date("1/1/2020")}
              fecha={desde}
              titulo={"Desde"}
            />
            <FechaEntrega
              setFecha={setHasta}
              min={new Date("1/1/2020")}
              fecha={hasta}
              titulo={"Hasta"}
            />
          </div>

          <div style={{ flex: 1, textAlign: "center" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              Eventual:
              <NumberFormat
                value={Math.round(totalLocal)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </span>
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              Ventas contado:
              <NumberFormat
                value={Math.round(total * 1.21)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </span>
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              Total:
              <NumberFormat
                value={Math.round(total * 1.21 + totalLocal)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </span>
          </div>
        </div>

        {cobrosLocales.length > 0 && (
          <>
            <div>
              <h3>Cobros eventuales</h3>
            </div>

            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Usuario</InputLabel>
                <Select
                  value={usuarioLocal}
                  onChange={(e) => setUsuarioLocal(e.target.value)}
                  className={classes.selectEmpty}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  label
                >
                  <MenuItem value={""}>Sin filtro</MenuItem>
                  {Object.keys(usuariosLocales).map((u) => (
                    <MenuItem value={u} key={u}>
                      {u}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Table>
              <TableHead>
                <TableCell style={{ fontWeight: "bold" }}>Fecha</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Usuario</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Cliente</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>NV</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>FC</TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="right">
                  Monto
                </TableCell>
              </TableHead>
              <TableBody>
                {cobrosLocales
                  .filter((d) => d.email.indexOf(usuarioLocal) > -1)
                  .map((c) => (
                    <TableRow key={c._id}>
                      <TableCell>
                        {dateFormat(c.fecha.toDate(), "dd/mm/yyyy hh:MM")}
                      </TableCell>
                      <TableCell>{c.email}</TableCell>
                      <TableCell>{c.cliente}</TableCell>
                      <TableCell>{c.nv}</TableCell>
                      <TableCell>{c.fc}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={Math.round(c.saldo)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </>
        )}

        <div>
          <h3>Ventas locales</h3>
        </div>

        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Usuario</InputLabel>
            <Select
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              className={classes.selectEmpty}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              label
            >
              <MenuItem value={""}>Sin filtro</MenuItem>
              {Object.keys(usuarios).map((u) => (
                <MenuItem value={u} key={u}>
                  {u}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Table>
          <TableHead>
            <TableCell style={{ fontWeight: "bold" }}>Fecha</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Usuario</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>NV</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Metodo</TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">
              Monto
            </TableCell>
          </TableHead>
          <TableBody>
            {cobros
              .filter((d) => d.cliente.indexOf(usuario) > -1)
              .map((c) => (
                <TableRow key={c._id}>
                  <TableCell>
                    {dateFormat(c.fecha, "dd/mm/yyyy hh:MM")}
                  </TableCell>
                  <TableCell>{c.cliente}</TableCell>
                  <TableCell>{c.numero}</TableCell>
                  <TableCell>{c.pago}</TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={Math.round(
                        c.articulos.reduce(
                          (p, c) => p + c.cantidad * c.precio,
                          0
                        ) * 1.21
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default Cobros;
