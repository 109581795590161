import React, { useState } from "react";
import "firebase/firestore";
import { Container, Paper } from "@material-ui/core";

export default ({ remito, registrarCobro }) => {
  const [formaPago, setFormaPago] = useState("E");
  // const [pagos, setPagos] = useState([]);
  const pagos = []
  const [config, setConfig] = useState({});

  return (
    <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
      <div style={{ display: "flex" }}>
        <Paper
          style={{
            padding: "8px",
            width: "100%",
            minWidth: "400px",
            minHeight: "70vh",
            marginRight: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              marginBottom: "8px",
              width: "100%",
              gap: "14px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "8px",
                flexDirection: "column",
                flex: 1,
                alignItems: "center"
              }}
            >
              <div>
                <p>Forma de Pago:</p>
                <select
                  value={formaPago}
                  onChange={(e) => {
                    setConfig({});
                    setFormaPago(e.target.value);
                  }}
                >
                  <option value="E">Efectivo</option>
                  <option value="TB">Transferencia</option>
                  <option value="PN">Tarjeta</option>
                </select>
              </div>

              {formaPago === "E" && (
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                  <p>
                    Saldo:{" "}
                    {
                    Math.round(
                    (remito.saldo -
                      Number(
                        pagos.reduce(
                          (prev, current) =>
                            Number(prev) + Number(current.Importe),
                          0
                        )
                      )) * 100 ) / 100 
                      
                      }
                  </p>
                  <div>
                    <label>Monto: {" "}</label>
                    <input
                      value={config?.monto || 0}
                      type="number"
                      min="0"
                      onChange={(e) => {
                        setConfig({ ...config, monto: e.target.value });
                      }}
                    />
                  </div>
                </div>
              )}
              {formaPago === "TB" && (
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                  <p>
                    Saldo:{" "}
                    {
                    Math.round(
                    (remito.saldo -
                      Number(
                        pagos.reduce(
                          (prev, current) =>
                            Number(prev) + Number(current.Importe),
                          0
                        )
                      )) * 100 ) / 100 
                      
                      }
                  </p>
                  <div>
                    <label>Monto: {" "}</label>
                    <input
                      value={config?.monto || 0}
                      type="number"
                      min="0"
                      onChange={(e) => {
                        setConfig({ ...config, monto: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <label>Nro transferencia: {" "}</label>
                    <input
                      value={config?.transferencia || ""}
                      onChange={(e) => {
                        setConfig({ ...config, transferencia: e.target.value });
                      }}
                    />
                  </div>
                </div>
              )}

              <div style={{marginTop: "30px"}}>
                <button
                 style={{fontSize: "30px"}}
                  onClick={() => {
                    // if (
                    //   Number(config?.monto) +
                    //     Number(
                    //       pagos.reduce(
                    //         (prev, current) =>
                    //           Number(prev) + Number(current.Importe),
                    //         0
                    //       )
                    //     ) >
                    //   remito.saldo
                    // )
                    //   return;

                    switch (formaPago) {
                      case "E": {
                        if (config.monto > 0) {

                           registrarCobro(remito, [
                            {
                              Numero: remito.pfv
                                ? Number(remito.fc)
                                : Number(
                                    remito.fc.substr(4, remito.fc.length - 4)
                                  ),
                              Puesto: remito.pfv,
                              Cliente: remito.cliente,
                              Importe: Number(config.monto),
                              Fecha: remito.fechafact,
                              LFV: remito.lfv
                            },
                          ]);

                          // setPagos([
                          //   ...pagos,
                          //   {
                          //     Numero: remito.pfv
                          //       ? Number(remito.fc)
                          //       : Number(
                          //           remito.fc.substr(4, remito.fc.length - 4)
                          //         ),
                          //     Puesto: remito.pfv,
                          //     Cliente: remito.cliente,
                          //     Importe: Number(config.monto),
                          //     Fecha: remito.fechafact,
                          //   },
                          // ]);
                          setConfig({});
                        }
                        return;
                      }

                      case "TB":
                        if (
                          config.monto > 0 &&
                          config?.transferencia?.length > 0
                        ) {
                          let fecha = new Date();
                          fecha = `${fecha.getFullYear()}${
                            fecha.getMonth() + 1
                          }${fecha.getDate()}`;

                          registrarCobro(remito, [
                            {
                              Numero: remito.pfv
                                ? Number(remito.fc)
                                : Number(
                                    remito.fc.substr(4, remito.fc.length - 4)
                                  ),
                              Puesto: remito.pfv,
                              Cliente: remito.cliente,
                              Importe: Number(config.monto),
                              Fecha: remito.fechafact,
                              Pago: "TB",
                              InfoPago: `4|${fecha}|${config.transferencia}`,
                              LFV: remito.lfv
                            },
                          ]);
                          // setPagos([
                          //   ...pagos,
                          //   {
                          //     Numero: remito.pfv
                          //       ? Number(remito.fc)
                          //       : Number(
                          //           remito.fc.substr(4, remito.fc.length - 4)
                          //         ),
                          //     Puesto: remito.pfv,
                          //     Cliente: remito.cliente,
                          //     Importe: Number(config.monto),
                          //     Fecha: remito.fechafact,
                          //     Pago: "TB",
                          //     InfoPago: `1|${fecha}|${config.transferencia}`,
                          //   },
                          // ]);
                          setConfig({});

                        }
                        return;
                      case "PN":
                        return;
                      default:
                        return;
                    }
                  }}
                >
                  Procesar Pago
                </button>
              </div>
            </div>

            {/* <div
              style={{
                display: "flex",
                marginBottom: "8px",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div>
                {pagos.map((p) => (
                  <div key={p.Importe}>{p.Importe}</div>
                ))}
              </div>

              <div>
                <button
                  disabled={!pagos.length}
                  onClick={() => {
                    registrarCobro(remito, pagos);
                  }}
                >
                  Procesar pagos
                </button>
              </div>
            </div> */}
          </div>
        </Paper>
      </div>
    </Container>
  );
};
