import React, { useState } from "react";
import firebase from "../../firebase";
import "firebase/firestore";
import { useHistory } from "react-router-dom";

import { Button, AppBar, Toolbar } from "@material-ui/core";

export default () => {
  const history = useHistory();

  const [nv, setNv] = useState("");

  const handleClick = (event) => {
    event.preventDefault();

    firebase
      .auth()
      .signOut()
      .then((res) => {
        history.push("/auth/login");
      });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Button
          style={{ marginLeft: "20px" }}
          variant="contained"
          onClick={() => history.push("/dashboard")}
          color="secondary"
        >
          Inicio
        </Button>

        <Button
          style={{ marginLeft: "20px" }}
          variant="contained"
          onClick={() => history.push("/pendientes")}
          color="secondary"
        >
          Ver Articulos Pendientes
        </Button>

        <Button
          style={{ marginLeft: "20px" }}
          variant="contained"
          onClick={() => history.push("/cobros")}
          color="secondary"
        >
          COBROS
        </Button>
        <Button
          style={{ marginLeft: "20px" }}
          variant="contained"
          onClick={() => history.push("/nvbyclients")}
          color="secondary"
        >
          NV Locales
        </Button>

        <span
          style={{ marginLeft: "40px", fontWeight: "bold", fontSize: "16px" }}
        >
          Numero Nota Venta
        </span>
        <input
          style={{ marginLeft: "10px" }}
          onChange={(e) => setNv(e.target.value)}
        />
        <Button
          style={{ marginLeft: "20px" }}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (nv.length > 0)
              window.open(
                `https://vallejosmateriales.com.ar/nota?nota=${nv}`,
                "_blank"
              );
          }}
        >
          Descargar pdf
        </Button>
        <Button
          style={{ marginLeft: "50px" }}
          variant="contained"
          color="secondary"
          onClick={handleClick}
        >
          Salir
        </Button>
      </Toolbar>
    </AppBar>
  );
};
