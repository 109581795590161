import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function MaterialUIPickers({ fecha, min, setFecha, titulo }) {
  const [selectedDate, setSelectedDate] = React.useState(fecha);

  const handleDateChange = (date) => {
    setSelectedDate(new Date(new Date(date).getTime() - 3 * 60 * 60 * 1000));
    setFecha(new Date(new Date(date).getTime() - 3 * 60 * 60 * 1000));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <div style={{ width: "144px", alignSelf: "center" }}>
          <span>{titulo}</span>
        </div>

        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Seleccionar fecha"
          format="dd/MM/yyyy"
          value={selectedDate}
          minDate={min}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}
