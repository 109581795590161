import React, { useState, useEffect } from "react";
import firebase from "../../../firebase";
import "firebase/firestore";
import { COLECCION_URL } from "../../../constantes";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
} from "@material-ui/core";

import MenuWeb from "./../menu";

const db = firebase.firestore();
const Pendientes = () => {
  const [filtro, setFiltro] = useState("");

  const [productos, setProductos] = useState({});
  useEffect(() => {
    db.collection(COLECCION_URL)
      .where("estado", "==", "pendiente")
      .orderBy("fecha", "desc")
      .orderBy("horario", "asc")
      .onSnapshot(function (querySnapshot) {
        let articulos = [];
        querySnapshot.forEach((d) => {
          articulos.push(...d.data().articulos);
        });

        let articulosReduce = articulos.reduce((prev, current) => {
          let index = current.articulo;

          prev[index] = prev[index] ? prev[index] : { ...current, cantidad: 0 };
          prev[index].cantidad += current.cantidad;

          return prev;
        }, {});

        setProductos(articulosReduce);
      });
  }, []);

  return (
    <React.Fragment key={"bottom"}>
      <MenuWeb />

      <Paper style={{ margin: "4%" }}>
        <Toolbar>
          <span>Buscar: </span>
          <input
            style={{ marginLeft: "20px" }}
            onChange={(e) => setFiltro(e.target.value)}
          />
        </Toolbar>

        <Table>
          <TableHead>
            <TableCell>Foto</TableCell>
            <TableCell>Cod</TableCell>
            <TableCell>Descripcion</TableCell>
            <TableCell>Cantidad</TableCell>
          </TableHead>
          <TableBody>
            {Object.keys(productos)
              .filter(
                (f) =>
                  f.toString().toLowerCase().indexOf(filtro.toLowerCase()) >
                    -1 ||
                  productos[f].descripcion
                    .toString()
                    .toLowerCase()
                    .indexOf(filtro.toLowerCase()) > -1
              )
              .map((key) => (
                <TableRow key={key}>
                  <TableCell>
                    {" "}
                    <img
                      alt="otra img"
                      src={
                        productos[key].foto
                          ? productos[key].foto
                          : "https://vallejosmateriales.com.ar/img/logo.png"
                      }
                      style={{
                        background: "white",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{key}</TableCell>
                  <TableCell>{productos[key].descripcion}</TableCell>
                  <TableCell>{productos[key].cantidad}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default Pendientes;
