import React, { useState, useEffect } from "react";
import firebase from "../../../firebase";
import "firebase/firestore";
import { COLECCION_PAGOS, COLECCION_URL, API } from "../../../constantes";

import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import dateFormat from "dateformat";

import MenuWeb from "./../menu";

import FechaEntrega from "./fechaEntrega";

import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const db = firebase.firestore();

const Cobros = () => {
  const classes = useStyles();

  const [cobros, setCobros] = useState([]);
  const [usuarios, setUsuarios] = useState({});

  const [email, setEmail] = useState("");

  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());

  const [total, setTotal] = useState(0);
  const [montoSinValidar, setMontoSinValidar] = useState(0);
  const [montoValidado, setMontoValidado] = useState(0);
  useEffect(() => {
    let fechaDesde = desde;
    let fechaHasta = hasta;

    // let money = 0;
    fechaDesde.setHours(0);
    fechaDesde.setMinutes(0);
    fechaHasta.setHours(23);
    fechaHasta.setMinutes(50);
    db.collection(COLECCION_PAGOS)
      .where("fecha", ">=", fechaDesde)
      .where("fecha", "<=", fechaHasta)
      .orderBy("fecha", "asc")
      .onSnapshot(function (querySnapshot) {
        let items = [];
        let emails = {};
        querySnapshot.forEach((d) => {
          items.push({ ...d.data(), id: d.id });
          // money = Number(money) + Number(d.data().monto);
          emails[d.data().email] = d.data().email;
        });
        setCobros(items);
        // setTotal(money);
        setUsuarios(emails);
      });
  }, [desde, hasta]);

  useEffect(() => {
    let money = cobros
      .filter((d) => d.email.indexOf(email.toLowerCase()) > -1)
      .reduce((a, b) => a + b.monto, 0);
    let val = cobros
      .filter((d) => d.email.indexOf(email.toLowerCase()) > -1 && d.validado)
      .reduce((a, b) => a + b.monto, 0);
    let sinval = cobros
      .filter((d) => d.email.indexOf(email.toLowerCase()) > -1 && !d.validado)
      .reduce((a, b) => a + b.monto, 0);
    // money = Number(money) + Number(d.data().monto);
    setTotal(money);
    setMontoValidado(val);
    setMontoSinValidar(sinval);
  }, [cobros, email]);
  /*  
    documento
    email
    fc
    fecha
    monto
    nv
    remito
    validado  
  */
  return (
    <React.Fragment key={"bottom"}>
      <MenuWeb />
      <Paper style={{ margin: "4%" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "8px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flex: 1, gap: "10px" }}>
            <FechaEntrega
              setFecha={setDesde}
              min={new Date("1/1/2020")}
              fecha={desde}
              titulo={"Desde"}
            />
            <FechaEntrega
              setFecha={setHasta}
              min={new Date("1/1/2020")}
              fecha={hasta}
              titulo={"Hasta"}
            />
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <span
              style={{ fontSize: "20px", fontWeight: "bold", color: "green" }}
            >
              Validado:
              <NumberFormat
                value={Math.round(montoValidado)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </span>
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <span
              style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}
            >
              Sin validar:
              <NumberFormat
                value={Math.round(montoSinValidar)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </span>
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              Monto total:
              <NumberFormat
                value={Math.round(total)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </span>
          </div>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Usuario</InputLabel>
            <Select
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.selectEmpty}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              label
            >
              <MenuItem value={""}>Sin filtro</MenuItem>
              {Object.keys(usuarios).map((u) => (
                <MenuItem value={u} key={u}>
                  {u}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Table>
          <TableHead>
            <TableCell style={{ fontWeight: "bold" }}>Fecha</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Usuario</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Factura</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Remito</TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="right">
              Monto
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Validar
            </TableCell>
          </TableHead>
          <TableBody>
            {cobros
              .filter((d) => d.email.indexOf(email.toLowerCase()) > -1)
              .map((c) => (
                <TableRow key={c.id}>
                  <TableCell>
                    {dateFormat(c.fecha.toDate(), "dd/mm/yyyy hh:MM")}
                  </TableCell>
                  <TableCell>{c.email}</TableCell>
                  <TableCell>{c.fc}</TableCell>
                  <TableCell>{c.remito}</TableCell>
                  <TableCell align="right">
                    <NumberFormat
                      value={c.monto}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {c.validado ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Validado
                      </span>
                    ) : (
                      validar(c)
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

const validar = (doc) => (
  <Button
    color="primary"
    variant="contained"
    onClick={async () => {
      let remitoDocument = await db
        .collection(COLECCION_URL)
        .doc(doc.remito)
        .get();
      const remito = remitoDocument.data();

      if (!remito.fechafact) return;

      let res = await fetch(`${API}/api/v1/remitos/registrar_pago`, {
        method: "POST",
        headers: { "Content-Type": "Application/json" },
        body: JSON.stringify({
          pago: [
            {
              Numero: remito.pfv
                ? Number(remito.fc)
                : Number(doc.fc.substr(4, doc.fc.length - 4)),
              Puesto: remito.pfv ? remito.pfv : doc.fc.substr(0, 4),
              Cliente: remito.cliente,
              Importe: doc.monto,
              Fecha: remito.fechafact,
              LFV: remito.lfv
            },
          ],
        }),
      });

      let responsePago = await res.json();

      if (
        responsePago &&
        responsePago.COGenerada &&
        responsePago.COGenerada.length > 0
      ) {
        if (responsePago.COGenerada[0].Respuesta === "OK") {
          db.collection(COLECCION_PAGOS).doc(doc.id).update({
            validado: true,
          });
        }
      }
    }}
  >
    Validar
  </Button>
);

export default Cobros;
