import React, { useState, useEffect, useContext } from "react";

import firebase from "../../../firebase";
import "firebase/firestore";

import { Paper, Drawer, Toolbar } from "@material-ui/core";

import Table from "./table";

import { COLECCION_URL, COLECCION_COBROLOCAL, API } from "../../../constantes";

import DetalleRemito from "./detalle_remito";

import ModalCobros from "./modal_cobros";

import MenuWeb from "./../menu";
import FechaEntrega from "./fechaEntrega";

import { AuthContext } from "./../../../AuthProvider";

const db = firebase.firestore();
const Dashboard = () => {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [envios, setEnvios] = useState([]);

  const [filtro, setFiltro] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalCobrosDrawer, setOpenModalCobrosDrawer] = useState(false);

  const [remito, setRemito] = useState(null);

  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(
    new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000)
  );

  useEffect(() => {
    const buscarDocumentos = async () => {
      try {
        setLoading(true);

        let fechaDesde = desde;
        let fechaHasta = hasta;

        // let money = 0;
        fechaDesde.setHours(0);
        fechaDesde.setMinutes(0);
        fechaHasta.setHours(23);
        fechaHasta.setMinutes(50);

        await db
          .collection(COLECCION_URL)
          .where("fecha", ">=", fechaDesde)
          .where("fecha", "<=", fechaHasta)
          .orderBy("fecha", "desc")
          .orderBy("horario", "asc")
          .onSnapshot(function (querySnapshot) {
            let documentos = [];
            querySnapshot.forEach((d) => {
              documentos.push({
                ...d.data(),
                ...{
                  id: d.id,
                  fecha: d.data().fecha.toDate(),
                  actualizacion: d.data().actualizacion
                    ? d.data().actualizacion.toDate()
                    : new Date(),
                  tipo: d.data().tipo === "moto",
                  horario: d.data().horario || 23,
                },
              });
            });
            setEnvios(documentos);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    buscarDocumentos();
  }, [desde, hasta]);


  const buscarPorNV = async () => {
    try{
      setLoading(true);
      const inputNV = document.getElementById("notaventa")

      let nv = inputNV.value
      nv = "0".repeat(8 - nv.length) + nv
      await db
      .collection(COLECCION_URL)
      .where("nv", "==", nv)
      .onSnapshot(function (querySnapshot) {
        let documentos = [];
        querySnapshot.forEach((d) => {
          documentos.push({
            ...d.data(),
            ...{
              id: d.id,
              fecha: d.data().fecha.toDate(),
              actualizacion: d.data().actualizacion
                ? d.data().actualizacion.toDate()
                : new Date(),
              tipo: d.data().tipo === "moto",
              horario: d.data().horario || 23,
            },
          });
        });
        setEnvios(documentos);
        setLoading(false);
      });
    }catch(error){
      setLoading(false);
      console.log(error)
    }
  }

  const registrarCobro = async (remito, pago) => {
    const notaVentaRes = await fetch(
      `${API}/api/v1/ventas/nota/${Number(remito.nv)}`,
      {}
    );
    const notaVentaBody = await notaVentaRes.json();

    try {
      setOpenModalCobrosDrawer(false)
      setLoading(true);
      let resApiPago = await fetch(`${API}/api/v1/remitos/registrar_pago`, {
        method: "POST",
        headers: { "Content-Type": "Application/json" },
        body: JSON.stringify({
          cliente: notaVentaBody?.cliente,
          rapidForm: notaVentaBody?.rapidForm,
          name: notaVentaBody?.rapidFormData?.name,
          tipoIVA: notaVentaBody?.rapidFormData?.tipoIVA,
          cuit: notaVentaBody?.rapidFormData?.cuit,
          telefono: notaVentaBody?.entrega?.telefono,
          pago,
        }),
      });

      let responsePago = await resApiPago.json();

      if (
        responsePago &&
        responsePago.COGenerada &&
        responsePago.COGenerada.length > 0
      ) {
        if (responsePago.COGenerada[0].Respuesta === "OK") {
          db.collection(COLECCION_URL).doc(remito.id).update({
            saldo: remito.saldo - pago[0].Importe,
          });

          await db.collection(COLECCION_COBROLOCAL).add({
            fecha: new Date(),
            email: user.email,
            nv: remito.nv,
            fc: remito.fc,
            cliente: remito.cliente,
            saldo: remito.saldo - pago[0].Importe,
          });

        }
      }

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const showDetail =  (item) => {
    // console.log(item)
    //  db.collection("prueba").doc(item.id).delete()
    setRemito(item);
    setOpenDrawer(true);
  };

  const showCobroModal = (item) => {
    setRemito(item);
    setOpenModalCobrosDrawer(true);
  };

  return (
    <React.Fragment key={"bottom"}>
      <MenuWeb />

      {loading ? (
        <Paper>
          <h4>Cargando...</h4>
        </Paper>
      ) : (
        <Paper style={{ margin: "4%" }}>
          <Toolbar>
            <FechaEntrega
              setFecha={setDesde}
              min={new Date("1/1/2020")}
              fecha={desde}
              titulo={"Desde"}
            />
            <FechaEntrega
              setFecha={setHasta}
              min={new Date("1/1/2020")}
              fecha={hasta}
              titulo={"Hasta"}
            />
            <div style={{ marginLeft: "10px" }}>
              <span>Filtrar: </span>
              <input
                style={{ marginLeft: "20px" }}
                onChange={(e) => setFiltro(e.target.value)}
              />
            </div>
            
          </Toolbar>
          <div style={{ marginLeft: "10px" }}>
              <span>Buscar por NV: </span>
              <input
                id="notaventa"
                style={{ marginLeft: "20px" }}
              />
              <button style={{ marginLeft: "20px" }} onClick={() => buscarPorNV()}>Buscar</button>
            </div>
          <Table
            registrarCobro={showCobroModal}
            rows={
              envios.filter(
                (f) =>
                  f.cliente
                    .toString()
                    .toLowerCase()
                    .indexOf(filtro.toLowerCase()) > -1 ||
                  f.observacion
                    .toString()
                    .toLowerCase()
                    .indexOf(filtro.toLowerCase()) > -1 ||
                  f.nv.toString().toLowerCase().indexOf(filtro.toLowerCase()) >
                    -1 ||
                  f.fc.toString().toLowerCase().indexOf(filtro.toLowerCase()) >
                    -1 ||
                  f.documento
                    .toString()
                    .toLowerCase()
                    .indexOf(filtro.toLowerCase()) > -1 ||
                  f.nombre
                    .toString()
                    .toLowerCase()
                    .indexOf(filtro.toLowerCase()) > -1
              ) || []
            }
            showDetail={showDetail}
          />
        </Paper>
      )}
      <Drawer
        anchor={"bottom"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <DetalleRemito remito={remito} />
      </Drawer>

      <Drawer
        anchor={"bottom"}
        open={openModalCobrosDrawer}
        onClose={() => setOpenModalCobrosDrawer(false)}
      >
        <ModalCobros remito={remito} registrarCobro={registrarCobro}/>
      </Drawer>
    </React.Fragment>
  );
};

export default Dashboard;
