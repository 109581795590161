import React, { useState, useEffect, Fragment } from "react";

import firebase from "../../../firebase";
import "firebase/firestore";
import {
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from "@material-ui/core";

import SaveIcon from "@material-ui/icons/Save";

import Mapa from "./mapa";

import dateFormat from "dateformat";

import FechaEntrega from "./fechaEntrega";

import { API, COLECCION_URL } from "../../../constantes";

const db = firebase.firestore();
const COLECCION_ENTREGAS = "entregas";
export default ({ remito }) => {
  const [acopio, setAcopio] = useState(-1);
  const [entrega, setEntrega] = useState(null);

  const [fecha, setFecha] = useState(remito.fecha);
  const [hora, setHora] = useState(remito.horario);
  const [estado, setEstado] = useState(remito.estado);
  const [tel, setTel] = useState(remito.tel);
  const [transporte, setTransporte] = useState(remito.transporte);
  const [nombre, setNombre] = useState(remito.nombre);
  const [direccion, setDireccion] = useState(remito.direccion);
  const [observacion, setObservacion] = useState(remito.observacion);


  useEffect(() => {
    const findNotaVenta = async () => {
      const notaVentaRes = await fetch(
        `${API}/api/v1/ventas/nota/${Number(remito.nv)}`,
        {}
      );
      const notaVentaBody = await notaVentaRes.json();
      
      const countAcopio = notaVentaBody.articulos.reduce((prev, current) => {
        return prev + (current.cantidad - current.remite)
      }, 0);
      
      setAcopio(countAcopio)
    }
    findNotaVenta();
  }, [remito.nv]);



  const save = async () => {
    await db
      .collection(COLECCION_URL)
      .doc(remito.id)
      .update({
        fecha: new Date(fecha),
        horario: Number(hora),
        estado,
        transporte,
        nombre,
        direccion,
        observacion,
        tel,
      });
    alert("Actualizado correctamente!");
  };

  useEffect(() => {
    db.collection(COLECCION_ENTREGAS)
      .where("remito", "==", remito.id)
      .onSnapshot(function (querySnapshot) {
        let documentos = [];
        querySnapshot.forEach((d) => {
          documentos.push({
            ...d.data(),
            ...{
              id: d.id,
              fecha: d.data().fecha.toDate(),
              actualizacion: d.data().actualizacion
                ? d.data().actualizacion.toDate()
                : new Date(),
              tipo: d.data().tipo === "moto",
              horario: d.data().horario || 23,
            },
          });
        });
        if (documentos.length > 0) {
          setEntrega(documentos[documentos.length - 1]);
        }
      });
  }, [remito.id]);

  return (
    <Container
      style={{ marginBottom: "20px", marginTop: "20px", maxHeight: "60vh" }}
    >
      <div style={{ display: "flex" }}>
        <Paper
          style={{
            padding: "8px",
            width: "40%",
            minWidth: "400px",
            marginRight: "10px",
          }}
        >
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span> Ultima actualizacion:</span>
            </div>
            <div>
              {dateFormat(new Date(remito.actualizacion), "dd/mm/yyyy HH:MM")}
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Tipo:</span>
            </div>
            <div>
              <select
                defaultValue={remito.transporte}
                style={{ width: "260px" }}
                onChange={(e) => setTransporte(e.target.value)}
              >
                <option value={"1"}>Reparto</option>
                <option value={"R"}>Deposito</option>
              </select>
            </div>
          </div>

          <FechaEntrega
            titulo={"Fecha entrega:"}
            setFecha={setFecha}
            // min={remito.fecha}
            fecha={entrega && entrega.fecha ? entrega.fecha : remito.fecha}
          />
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Hora:</span>
            </div>
            <div>
              <input
                onChange={(e) => setHora(e.target.value)}
                type="number"
                defaultValue={remito.horario}
                style={{ width: "260px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Cliente:</span>
            </div>
            <div>
              <input
                defaultValue={remito.nombre}
                style={{ width: "260px" }}
                onChange={(e) => setNombre(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Direccion:</span>
            </div>
            <div>
              <input
                defaultValue={remito.direccion}
                style={{ width: "260px" }}
                onChange={(e) => setDireccion(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Telefono:</span>
            </div>
            <div>
              <input
                defaultValue={remito.tel}
                style={{ width: "260px" }}
                onChange={(e) => setTel(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Estado:</span>
            </div>
            <div>
              <select
                defaultValue={remito.estado}
                style={{ width: "260px" }}
                onChange={(e) => setEstado(e.target.value)}
              >
                <option value={"pendiente"}>Pendiente</option>
                <option value={"camino"}>En camino</option>
                <option value={"entregado"}>Entregado</option>
                <option value={"anulado"}>Anulado</option>
              </select>
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div style={{ width: "144px" }}>
              <span>Observacion:</span>
            </div>
            <div>
              <input
                defaultValue={remito.observacion}
                style={{ width: "260px" }}
                onChange={(e) => setObservacion(e.target.value)}
              />
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>IMG</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Articulo</TableCell>
                <TableCell>Cantidad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {remito.articulos.map((a) => (
                <TableRow key={a.articulo}>
                  <TableCell>
                    <img
                      alt="otra img"
                      src={
                        a.foto
                          ? a.foto
                          : "https://vallejosmateriales.com.ar/img/logo.png"
                      }
                      style={{
                        background: "white",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{a.articulo}</TableCell>
                  <TableCell>{a.descripcion}</TableCell>
                  <TableCell>{a.cantidad}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <div style={{display: "flex", flexDirection: "column"}}>
          {acopio > 0 && (
            <div><h3 style={{color: "rgb(43 152 32)", border: "solid 1px", padding: "4px"}}>Tiene acopio!!</h3></div>)}
            
            {acopio < 0 && (
              <div><h3 style={{color: "rgb(0, 0, 0)", padding: "4px"}}>Verificando acopio....</h3></div> )}
          

          <div style={{ width: "64px", marginLeft: "5px" }}>
            <IconButton color={"secondary"} onClick={() => save()}>
              <SaveIcon color={"primary"} />
            </IconButton>
          </div>
        </div>

        {entrega && (
          <Fragment>
            {entrega.firma && (
              <Paper
                style={{ padding: "8px", width: "170px", marginRight: "10px" }}
              >
                <h3>Firma</h3>
                <img
                  alt="otra img"
                  src={entrega.firma}
                  style={{ objectFit: "cover", width: "150px", height: "200" }}
                />
                <p>{entrega.nombre}</p>
                <p>{entrega.dni}</p>
              </Paper>
            )}

            {entrega.latitud && entrega.longitud && (
              <Paper style={{ padding: "8px", width: "270px" }}>
                <Mapa
                  lat={entrega.latitud.toString()}
                  lon={entrega.longitud.toString()}
                />
              </Paper>
            )}
            {(!entrega.latitud || !entrega.longitud) && (
              <span>No se aceptaron los permisos de gps</span>
            )}
          </Fragment>
        )}
      </div>
    </Container>
  );
};
