import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AuthRoutes } from "../views/Auth";
import PrivateRoute from "../PrivateRoute";
import { DashboardRoutes } from "../views/Dashboard";

import Sincronizar from "../views/Dashboard/components/sincronizar";
import Pendientes from "../views/Dashboard/components/pendientes";
import Cobros from "../views/Dashboard/components/cobros";
import NvByClients from "../views/Dashboard/components/NvByClients";

const ApplicationRoutes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={DashboardRoutes} />
        <PrivateRoute exact path="/pendientes" component={Pendientes} />
        <PrivateRoute exact path="/cobros" component={Cobros} />
        <PrivateRoute exact path="/nvbyclients" component={NvByClients} />
        <PrivateRoute exact path="/admin" component={HolaMundo} />
        <Route path="/auth" component={AuthRoutes} />
        <Redirect to="/auth" from="/" />
      </Switch>
    </Router>
  );
};

export default ApplicationRoutes;

const HolaMundo = () => (
  <span>
    <Sincronizar />
  </span>
);
