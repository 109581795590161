let firebaseConfig = {
  apiKey: "AIzaSyCRSjEZBrM_EPdTs73tYr782fZTkr6DbHU",
  authDomain: "vallejos-envios.firebaseapp.com",
  databaseURL: "https://vallejos-envios.firebaseio.com",
  projectId: "vallejos-envios",
  storageBucket: "vallejos-envios.appspot.com",
  messagingSenderId: "151587819363",
  appId: "1:151587819363:web:21f696666d004a3be20d56",
  measurementId: "G-536LCQL09T",
};

export default firebaseConfig;
