import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import axios from "axios";

import { API } from "./../../../constantes";

export default function MaterialUIPickers() {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(new Date().getTime() - 3 * 60 * 60 * 1000)
  );

  const handleDateChange = (date) => {
    setSelectedDate(new Date(new Date(date).getTime() - 3 * 60 * 60 * 1000));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="center">
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Seleccionar fecha"
          format="dd/MM/yyyy"
          value={selectedDate}
          minDate={new Date("08/18/2020")}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "10px" }}
          onClick={() =>
            axios(`${API}/remitos`, {
              method: "POST",
              headers: {
                "content-Type": "Application/json",
              },
              data: {
                fecha: selectedDate,
              },
            })
          }
        >
          Sincronizar remitos
        </Button>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
