import React from "react";
import GoogleMapReact from "google-map-react";

const SimpleMap = ({ lat, lon }) => {
  return (
    <div style={{ height: "400px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBlpq13-43QQgu2mUDffuxujmHh3GV90rs" }}
        defaultCenter={{ lat: Number(lat), lng: Number(lon) }}
        defaultZoom={16}
      >
        <Marker lat={lat} lng={lon} />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;

const Marker = () => {
  return (
    <img
      alt="la imagen"
      style={{
        background: "white",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
      }}
      src={"https://vallejosmateriales.com.ar/img/logo.png"}
    />
  );
};
